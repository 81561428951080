import debounce from "lodash.debounce";
import Cookies from "js-cookie";
import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ApiError, createApiKey, getCloudInstanceConfig, getOrCreateInstance } from "../api-client/ApiClient";
import { DeactivatedPagePath, NewUserNotAllowedPagePath, SubscriptionPagePath } from "../App";
import {
  GetCloudInstanceConfigResponse,
  GetOrCreateApiKeyResponse,
  GetOrCreateInstanceResponse,
} from "../gen/proto/kurtosis_backend_server_api_pb";
import { startHandler, stopHandler } from "../utils/debounce";
import { useAppContext } from "./AppState";
import { TosAckCookieName, TosVersion } from "../Settings";

/*
 * This component has memoization and debouncing implemented to ensure the
 * access token is only sent _once_ to the API.
 * */

const InstanceState = () => {
  const { appData, setAppData } = useAppContext();
  const navigate = useNavigate();

  const debouncedCheckTos = debounce(getTosAck, 2000, { leading: true });
  const debouncedCheckTosCallback = useCallback(debouncedCheckTos, []);
  useEffect(() => {
    if (appData.jwtToken && appData.externalUserId) {
      debouncedCheckTosCallback(appData.jwtToken, appData.externalUserId);
    }
  }, [appData.externalUserId, appData.jwtToken]);

  function getTosAck(accessToken: string, externalUserId: string) {
      const ack = Cookies.get(TosAckCookieName);
      if (ack !== TosVersion) {
        setAppData({
          ...appData,
          jwtToken: accessToken,
          externalUserId: externalUserId,
          tosAck: false,
        });
      } else {
        setAppData({
          ...appData,
          jwtToken: accessToken,
          externalUserId: externalUserId,
          tosAck: true,
        });
      }
  }

  // TODO: use `useMemo` instead. Can't get it working for some reason: useMemo(debounce(getOrCreateApiKey, 500), []);
  const debouncedCheckUser = debounce(getOrCreateApiKey, 2000, { leading: true });
  const debouncedCheckUserCallBack = useCallback(debouncedCheckUser, []);
  useEffect(() => {
    if (appData.jwtToken && appData.externalUserId && appData.tosAck) {
      debouncedCheckUserCallBack(appData.jwtToken);
    }
  }, [appData.externalUserId, appData.jwtToken, appData.tosAck]);

  function getOrCreateApiKey(accessToken: string) {
    if (accessToken && accessToken.length > 0) {
      createApiKey(accessToken)
        .then((res) => {
          res.match({
            Err(error: ApiError) {
              handleError(error);
            },
            Ok(value: GetOrCreateApiKeyResponse) {
              setAppData({
                ...appData,
                apiKey: value.apiKey,
                jwtToken: accessToken,
              });
            },
          });
        })
        .catch((error) => handleError(error))
        .finally(() => debouncedCheckUser.cancel());
    } else {
      handleError(`No access token existed`);
    }
  }

  const handleError = (error: any) => {
    if (error.message.toLowerCase().includes("inactive user")) {
      console.warn("User has been deactivated");
      setAppData({
        ...appData,
        isLoadingInstance: false,
        active: false,
      });
      navigate(DeactivatedPagePath);
    } else if (error.message.toLowerCase().includes("new user not allowed")) {
      console.warn("New users are not allowed at this time");
      setAppData({
        ...appData,
        isLoadingInstance: false,
        active: false,
      });
      navigate(NewUserNotAllowedPagePath);
    } else {
      console.error(error);
    }
  };

  const getOrCreateInstanceByApiKey = (apiKey: string) => {
    if (apiKey && apiKey.length > 0) {
      getOrCreateInstance(apiKey).then((res) => {
        res.match({
          Err(error: ApiError) {
            handleError(error);
          },
          Ok(value: GetOrCreateInstanceResponse) {
            setAppData({
              ...appData,
              instanceId: value.instanceId,
            });
          },
        });
      });
    }
  };

  useEffect(() => {
    if (appData.apiKey
        && appData.apiKey.length > 0
        && (appData.active === undefined || appData.active)
        && window.location.pathname !== SubscriptionPagePath) {
      getOrCreateInstanceByApiKey(appData.apiKey);
    }
  }, [appData.apiKey, appData.active, window.location.pathname]);

  const getInstanceStatus = (apiKey: string, instanceId: string) => {
    if (apiKey && apiKey.length > 0 && instanceId && instanceId.length > 0) {
      getCloudInstanceConfig(instanceId, apiKey).then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while querying for instance status", error);
            handleError(error);
          },
          Ok(value: GetCloudInstanceConfigResponse) {
            if (value.instanceId && value.instanceId.length > 0 && value.status && value.status.length > 0) {
              console.log(`got instance ${value.instanceId} with ip ${value.launchResult?.ipAddress} from database`);
              setAppData({
                ...appData,
                instanceId: value.instanceId,
                instanceStatus: value.status,
                remoteApiHostIp: value.launchResult?.ipAddress,
                instanceCreated: value.created,
              });
            } else {
              console.log(`Did not instance id ${instanceId} from database`);
            }
          },
        });
      });
    }
  };
  const checkStatus = () => {
    if (appData.apiKey && appData.apiKey.length > 0 && appData.instanceId && appData.instanceId.length > 0) {
      getInstanceStatus(appData.apiKey, appData.instanceId);
    }
  };
  const timerIdRef = useRef();
  useEffect(() => {
    // @ts-ignore
    timerIdRef.current = startHandler(timerIdRef, checkStatus, 2000);
    return () => stopHandler(timerIdRef);
  }, [appData.instanceId, appData.apiKey]);

  useEffect(() => {
    if (appData.instanceStatus === "running") {
      stopHandler(timerIdRef);
      setAppData({
        ...appData,
        isLoadingInstance: false,
      });
    } else {
      // Debounce the change:
      if (!appData.isLoadingInstance) {
        setAppData({
          ...appData,
          isLoadingInstance: true,
        });
      }
    }
  }, [appData.instanceStatus, appData.isLoadingInstance]);

  return <></>;
};

export default InstanceState;
