import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef } from "react";
import { useAppContext } from "./AppState";

const LoadJwtTokenToAppState = () => {
  const { user, getAccessTokenSilently, isLoading, isAuthenticated, error } = useAuth0();
  const { appData, setAppData } = useAppContext();
  const loading = useRef(false); // This effectively acts as a mutex and debounces the extra renders

  const getAccessToken = async () =>
    getAccessTokenSilently({
      authorizationParams: {
        // scope: "read:current_user",
      },
    });

  const loadAccessTokenToAppState = () =>
    getAccessToken()
      .then((accessToken) => {
        if (accessToken && accessToken.length > 0) {
          setAppData({
            ...appData,
            jwtToken: accessToken,
            externalUserId: user?.sub,
          });
        }
      })
      .catch((error) => console.error("An error occurred while getting the jwt token", error));

  useEffect(() => {
    if (!appData.jwtToken && !isLoading && isAuthenticated && !error && !loading.current) {
      loading.current = true;
      loadAccessTokenToAppState().then((r) => {
        console.log("Loaded jwt token from Auth0");
      });
    }
  }, [appData.jwtToken, loading.current, isLoading, isAuthenticated]);

  return <></>;
};

export default LoadJwtTokenToAppState;
