import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { EnclaveManagerPath } from "../App";
import { useAppContext } from "../context/AppState";
import { TosAckCookieName, TosAckCookieExpirationDays, TosVersion } from "../Settings";

export const Tos = () => {
  const navigate = useNavigate();
  const { appData, setAppData } = useAppContext();

  function ackTos() {
    Cookies.set(TosAckCookieName, TosVersion, { sameSite: "strict", expires: TosAckCookieExpirationDays });
    setAppData({
      ...appData,
      tosAck: true,
    });
    navigate(-1);
  }

  return (
    <>
      <Center>
        <Box mt={"100px"} width={"600px"}>
          By using the Kurtosis Cloud, you agree to the Kurtosis Cloud&nbsp;
          <Link href="https://www.kurtosis.com/cloud-terms-of-service" isExternal>
            Terms of Service <ExternalLinkIcon mx="4px" />
          </Link>
          <Box marginTop={10} marginBottom={10}>
            <Button colorScheme='green' mr={3} onClick={ackTos}>
              I agree
            </Button>
          </Box>
        </Box>
      </Center>
    </>
  );
};
