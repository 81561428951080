import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef, useState } from "react";

import { ApiError, CancelPaymentSubscription, GetOrCreatePaymentConfig } from "../api-client/ApiClient";
import { useAppContext } from "../context/AppState";
import { TosCheck } from "../context/TosCheck";
import {
  GetOrCreatePaymentConfigResponse,
  PaymentMethod,
  PaymentProduct,
  PaymentSubscription,
  PaymentSubscription_Status,
} from "../gen/proto/kurtosis_backend_server_api_pb";
import { SupportEmail } from "../Settings";
import { PaymentMethodForm } from "./PaymentMethodForm";
import { PaymentMethodSetupIntentStatus } from "./PaymentMethodSetupIntentStatus";
import { PaymentMethodPaymentIntentStatus } from "./PaymentMethodPaymentIntentStatus";
import {BrowserRecommendator} from "./BrowserRecommendator";

const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_KEY as string);

const GetStripeElementOptions = (clientSecret: string) => {
  const options: StripeElementsOptions = {
    // passing the SetupIntent's client secret
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      theme: useColorModeValue("stripe", "night"),
    },
  };

  return options;
};

const DisplayPaymentMethodForm = (clientSecret: string, paymentSubscription: PaymentSubscription | undefined) => {
  const options = GetStripeElementOptions(clientSecret);
  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentMethodForm paymentSubscription={paymentSubscription} />
    </Elements>
  );
};

const DisplayPaymentMethod = (paymentMethod: PaymentMethod) => {
  return (
    <Box marginTop={"10px"}>
      You have a card on file expiring on {paymentMethod.expMonth}/{paymentMethod.expYear} and ending in&nbsp;
      {paymentMethod.lastFour}. You can update your payment method below.
    </Box>
  );
};

class Disclosure {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  constructor(isOpen: boolean, onOpen: () => void, onClose: () => void) {
    this.isOpen = isOpen;
    this.onOpen = onOpen;
    this.onClose = onClose;
  }
}

const DisplayPaymentActiveSubscription = (
  accessToken: string,
  disclosure: Disclosure,
  cancelRef: React.MutableRefObject<null>,
  isDisabled: boolean,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  reason: string,
  setReason: React.Dispatch<React.SetStateAction<string>>,
) => {
  const handleCancel = async (reason: string) => {
    console.log("handleCancel");
    CancelPaymentSubscription(accessToken, reason)
      .then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while cancelling the subscription", error);
            handleError(error);
          },
          Ok() {
            setMessage("Your subscription will be cancelled at the end of the billing period.");
            setIsDisabled(true);
          },
        });
      })
      .catch((error) => console.error("An error occurred while cancelling the subscription", error))
      .finally(() => disclosure.onClose());
  };

  const handleError = (error: ApiError) => {
    if (error) {
      if (error.message !== undefined) {
        setMessage(error.message);
      } else {
        setMessage("Internal error");
      }
    }
  };

  const setMessage = (message: string) => {
    var msg = document.getElementById("subscription_desc");
    if (msg) {
      msg.innerHTML = "<b>" + message + "</b>";
    }
  };
  
  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value)

  return (
    <Box marginTop={"10px"}>
      <p id="subscription_desc">You have an active subscription.</p>
      <Box marginTop={"10px"}>
        <Button colorScheme="red" onClick={disclosure.onOpen} isDisabled={isDisabled}>
          Cancel Subscription
        </Button>
      </Box>

      <AlertDialog isOpen={disclosure.isOpen} leastDestructiveRef={cancelRef} onClose={disclosure.onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel Subscription
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Your subscription will be cancelled at the end of the billing period. Are you sure? You can't undo this action afterwards. If you decide to cancel your subscription, we would appreciate if you could provide the reason which would help us improve our product offering.</Text>
              <Box marginTop={"10px"}>
                <Input value={reason} onChange={handleReasonChange} placeholder="Reason" />
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={disclosure.onClose}>
                No
              </Button>
              <Button colorScheme="red" onClick={() => handleCancel(reason)} ml={3}>
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

const DisplayPaymentCancelledSubscription = () => {
  return (
    <Box marginTop={"10px"}>
      <p>Your subscription has been cancelled.</p>
    </Box>
  );
};

const DisplayPaymentCancelAtPeriodEndSubscription = () => {
  return (
    <Box marginTop={"10px"}>
      <p>Your subscription will be cancelled at the end of the billing period.</p>
    </Box>
  );
};

const DisplayPaymentMethodSetupIntentStatus = (clientSecret: string) => {
  const options = GetStripeElementOptions(clientSecret);
  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentMethodSetupIntentStatus />
    </Elements>
  );
};

const DisplayPaymentMethodPaymentIntentStatus = (clientSecret: string) => {
  const options = GetStripeElementOptions(clientSecret);
  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentMethodPaymentIntentStatus />
    </Elements>
  );
};

const DisplaySupportEmail = (supportEmail: string) => {
  return (
    <Box marginTop={"10px"}>
      <Link href={supportEmail} isExternal>
        You can email us if you need support.
        <ExternalLinkIcon mx="2px" />
      </Link>
    </Box>
  );
};

export const SubscriptionPaymentPage = () => {
  const { appData } = useAppContext();
  const [clientSecret, setClientSecret] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentProduct, setPaymentProduct] = useState<PaymentProduct>();
  const [paymentSubscription, setPaymentSubscription] = useState<PaymentSubscription>();
  const [supportEmail, setSupportEmail] = useState<string>("");
  const debouncedPaymentConfig = debounce(getOrCreatePaymentConfig, 2000, { leading: true });
  const debouncedPaymentConfigCallback = useCallback(debouncedPaymentConfig, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const disclosure = new Disclosure(isOpen, onOpen, onClose);
  const cancelRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("")

  const setupIntentClientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");
  const paymentIntentClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

  useEffect(() => {
    if (appData.jwtToken) {
      const setupIntent = true;
      debouncedPaymentConfigCallback(appData.jwtToken, setupIntent);
    }
  }, [appData.jwtToken]);

  function getOrCreatePaymentConfig(accessToken: string, setupIntent: boolean) {
    GetOrCreatePaymentConfig(accessToken, setupIntent)
      .then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while querying for payment config", error);
            handleError(error);
          },
          Ok(value: GetOrCreatePaymentConfigResponse) {
            if (value.userId && value.userId.length > 0) {
              console.log(`got payment user ID ${value.userId} from database`);
              setClientSecret(value.clientSecret);
              setPaymentMethod(value.paymentMethod);
              setPaymentProduct(value.product);
              setPaymentSubscription(value.subscription);
              setSupportEmail(SupportEmail);
            } else {
              console.log(`Did not get payment config from database`);
            }
          },
        });
      })
      .catch((error) => console.error("An error occurred while getting the payment config", error))
      .finally(() => debouncedPaymentConfig.cancel());
  }

  const handleError = (error: any) => {
    console.error(error);
  };

  return (
    <>
      <BrowserRecommendator />
      <TosCheck />
      {setupIntentClientSecret === null &&
        paymentIntentClientSecret === null &&
        paymentSubscription !== undefined &&
        paymentSubscription.status === PaymentSubscription_Status.ACTIVE &&
        DisplayPaymentActiveSubscription(appData.jwtToken as string, disclosure, cancelRef, isDisabled, setIsDisabled, reason, setReason)}
      {setupIntentClientSecret === null &&
        paymentIntentClientSecret === null &&
        paymentSubscription !== undefined &&
        paymentSubscription.status === PaymentSubscription_Status.CANCELLED &&
        DisplayPaymentCancelledSubscription()}
      {setupIntentClientSecret === null &&
        paymentIntentClientSecret === null &&
        paymentSubscription !== undefined &&
        paymentSubscription.status === PaymentSubscription_Status.ACTIVE_CANCEL_AT_PERIOD_END &&
        DisplayPaymentCancelAtPeriodEndSubscription()}
      {setupIntentClientSecret === null && paymentIntentClientSecret === null && paymentSubscription !== undefined && supportEmail !== "" && DisplaySupportEmail(supportEmail)}
      {paymentSubscription !== undefined && paymentSubscription.status === PaymentSubscription_Status.ACTIVE && paymentMethod !== undefined && setupIntentClientSecret === null && paymentIntentClientSecret === null && DisplayPaymentMethod(paymentMethod)}
      {clientSecret !== "" && setupIntentClientSecret === null && paymentIntentClientSecret === null && DisplayPaymentMethodForm(clientSecret, paymentSubscription)}
      {clientSecret !== "" && setupIntentClientSecret !== null && DisplayPaymentMethodSetupIntentStatus(clientSecret)}
      {clientSecret !== "" && paymentIntentClientSecret !== null && DisplayPaymentMethodPaymentIntentStatus(clientSecret)}
    </>
  );
};
