import { useAuth0 } from "@auth0/auth0-react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Code,
  Heading,
  Progress,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiError, GetOrCreatePaymentConfig } from "../api-client/ApiClient";
import { EnclaveManagerPath } from "../App";
import { useAppContext } from "../context/AppState";
import { TosCheck } from "../context/TosCheck";
import { GetOrCreatePaymentConfigResponse, PaymentSubscription } from "../gen/proto/kurtosis_backend_server_api_pb";
import { DefaultErrorHeadline, ErrorComponent } from "./ErrorPage";

export const formatTime = (timeMs: number) => {
  if (isNaN(timeMs)) return "calculating...";
  const formattedTimeMs = Math.floor(timeMs / 1e3);
  if (formattedTimeMs < 1) return "any second now...";
  return `${toTime(formattedTimeMs)}`;
};

const toTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19);
};

export const ProvisionInstance = () => {
  const { appData } = useAppContext();
  const { isLoading } = useAuth0();
  const [error] = useState(false);
  const navigate = useNavigate();
  const [paymentSubscription, setPaymentSubscription] = useState<PaymentSubscription>();
  const debouncedPaymentConfig = debounce(getOrCreatePaymentConfig, 2000, { leading: true });
  const debouncedPaymentConfigCallback = useCallback(debouncedPaymentConfig, []);

  // Manage the countdown and the progress bar
  const [elapsed, setElapsed] = useState(0);
  const defaultWaitTimeSecs = Math.floor(3 * 60);
  const maxWaitTimeMs = defaultWaitTimeSecs * 1000;
  const timeRemaining = (created: string) => {
    const now = new Date();
    const createdDate = new Date(created);
    // @ts-ignore
    const elapsedMs = now - createdDate;
    const remainingRaw = maxWaitTimeMs - elapsedMs;
    const remainingMs = remainingRaw < 0 ? 0 : remainingRaw;
    return { elapsedMs, remainingMs };
  };

  const [timerTick, setTimerTick] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  useEffect(() => {
    if (appData.instanceCreated) {
      const timer = setInterval(() => {
        // @ts-ignore
        const { elapsedMs, remainingMs } = timeRemaining(appData.instanceCreated);
        if (!isNaN(elapsedMs)) setElapsed(elapsedMs);
        setRemainingTime(remainingMs);
        setTimerTick(timerTick + 1);
      }, 200);
      return () => clearInterval(timer);
    }
    return () => ({});
  }, [timerTick, appData.instanceCreated]);

  function getOrCreatePaymentConfig(accessToken: string, setupIntent: boolean) {
    GetOrCreatePaymentConfig(accessToken, setupIntent)
      .then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while querying for payment config", error);
          },
          Ok(value: GetOrCreatePaymentConfigResponse) {
            if (value.userId && value.userId.length > 0) {
              console.log(`got payment user ID ${value.userId} from database`);
              setPaymentSubscription(value.subscription);
            } else {
              console.log(`Did not get payment config from database`);
            }
          },
        });
      })
      .catch((error) => console.error("An error occurred while getting the payment config", error))
      .finally(() => debouncedPaymentConfig.cancel());
  }

  useEffect(() => {
    if (appData.jwtToken && appData.instanceId) {
      const setupIntent = false;
      debouncedPaymentConfigCallback(appData.jwtToken, setupIntent);
    }
  }, [appData.instanceId, appData.jwtToken]);

  if (appData?.instanceStatus && appData?.instanceStatus === "running") {
    const searchParams = new URLSearchParams(window.location.search);
    navigate(`${EnclaveManagerPath}?${searchParams}`);
    return <></>;
  } else {
    return (
      <>
        <TosCheck />
        {error && <ErrorComponent headline={DefaultErrorHeadline} content={"An error has occurred"} />}
        {!error && (isLoading || !appData.apiKey) && (
          <>
            <Heading as="h2" size="md">
              One moment please
            </Heading>
            <br />
            <p>Authenticating...</p>
            <Center>
              <Spinner />
            </Center>
          </>
        )}
        {!appData?.instanceStatus && appData.apiKey && (
          <>
            <Heading as="h2" size="md">
              One moment please
            </Heading>
            <br />
            <p>Loading instance status...</p>
            <Center>
              <Spinner />
            </Center>
          </>
        )}
        {appData?.instanceStatus && appData?.instanceStatus !== "running" && (
          <>
            <Box margin={"10px"}>
              <Box marginTop={"20px"}>
                <Heading as="h2" size="2xl">
                  Get Excited
                </Heading>
              </Box>
              <Box marginTop={"10px"}>
                <Heading as="h2" size="sm">
                  We are creating your cloud instance!
                </Heading>
              </Box>
              <Box marginTop={"25px"}>
                <Progress
                  borderRadius={"5"}
                  hasStripe
                  height="20px"
                  value={elapsed}
                  max={maxWaitTimeMs}
                  isAnimated={true}
                />
              </Box>
              <Box marginTop={"25px"}>
                <p>Estimated wait: {formatTime(remainingTime)}</p>
              </Box>
              <Box marginTop={"5px"}>
                Status: <Code>{appData?.instanceStatus}</Code>
              </Box>
              {paymentSubscription === undefined && (
                <Box marginTop={"10px"}>
                  Your cloud instance will stay up for 12 hours. Please start a subscription to continue using your cloud instance past 12 hours.
                </Box>
              )}
              {/*<Progress hasStripe colorScheme='blue' height='20px' isIndeterminate={true} isAnimated={true}/>*/}
            </Box>
          </>
        )}
      </>
    );
  }
};
