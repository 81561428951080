import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { DefaultErrorHeadline, ErrorComponent } from "./ErrorPage";

/**
 * Note this page must exist and be wired to a route that matches what's defined in the Auth0 settings for allowed callback paths.
 */
export const CallbackPage = () => {
  const { error } = useAuth0();
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    if (error) {
      console.error("An error occurred while signing in the user", error);
      const content = "An error occurred while signing in. Please try again";
      const component = <ErrorComponent headline={DefaultErrorHeadline} content={content} />;
      setContent(component);
    }
  }, [error]);

  return <>{content}</>;
};
