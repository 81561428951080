import { Box, Button } from "@chakra-ui/react";
import { AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiError, UpdateAddress } from "../api-client/ApiClient";
import { SubscriptionPaymentPagePath } from "../App";
import { SupportedCountries } from "../Settings";

interface AddressFormProps {
  accessToken: string;
}

export const AddressForm: React.FC<AddressFormProps> = ({accessToken}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("handleSubmit");
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
    
    const addressElement = elements.getElement('address');
    if (addressElement === null) {
      setMessage("Error retrieving the address");
    } else {
      const {complete, value} = await addressElement.getValue();
      if (complete) {
        if (!SupportedCountries.includes(value.address.country)) {
          setMessage("Your country is not supported yet.")
        } else {
          var line2: string | undefined;
          if (value.address.line2 === null) {
            line2 = undefined;
          } else {
            line2 = value.address.line2;
          }
          UpdateAddress(accessToken, value.name, value.address.line1, line2, value.address.city, value.address.state, value.address.postal_code, value.address.country)
          .then((res) => {
            res.match({
              Err(error: ApiError) {
                console.error("Error while updating the address", error);
                handleError(error);
              },
              Ok() {
                navigate(SubscriptionPaymentPagePath);
              },
            });
          })
          .catch((error) => console.error("An error occurred while updating the address", error))
        }
      } else {
        setMessage("Your address is incomplete.")
      }
    }
  };

  const handleError = (error: ApiError) => {
    if (error) {
      if (error.message !== undefined) {
        setMessage(error.message);
      } else {
        setMessage("Internal error");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box marginTop={10} marginBottom={10} w="50%">
        <AddressElement options={{mode: 'billing'}} />
      </Box>
      <Box marginTop={10} marginBottom={10} w="50%">
        <Button size={"lg"} bg={"rgb(0, 194, 36)"} disabled={!stripe} type="submit">
          Submit
        </Button>
        <Box marginTop={10} marginBottom={10} w="50%">
          {message !== "" && <div>{message}</div>}
        </Box>
      </Box>
    </form>
  );
};
