import { Box, CircularProgress } from "@chakra-ui/react";
import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { ApiError, RefreshDefaultPaymentMethod } from "../api-client/ApiClient";
import { useAppContext } from "../context/AppState";

export const PaymentMethodSetupIntentStatus = () => {
  const { appData, setAppData } = useAppContext();
  const [message, setMessage] = useState<string>("");
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(true);
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");

    const interval = setInterval(() => {
      if (!clientSecret) {
        setIsIndeterminate(false);
        setMessage("Client secret not found in the request.");
        clearInterval(interval);
        return;
      }
      stripe
        .retrieveSetupIntent(clientSecret)
        .then(({ setupIntent }) => {
          if (!setupIntent) {
            setIsIndeterminate(false);
            setMessage("Failed to retrieve the payment intent details.");
            return;
          }
          switch (setupIntent.status) {
            case "succeeded":
              clearInterval(interval);
              if (!appData.jwtToken) {
                setIsIndeterminate(false);
                setMessage("No access token found");
                return;
              }
              if (!setupIntent.payment_method) {
                  setIsIndeterminate(false);
                  setMessage("Setup intent does not contain a payment method");
                  return;
              }
              const paymentMethodId = setupIntent.payment_method as string;
              RefreshDefaultPaymentMethod(appData.jwtToken, paymentMethodId).then((res) => {
                res.match({
                  Err(error: ApiError) {
                    setIsIndeterminate(false);
                    setMessage(error.message);
                  },
                  Ok() {
                    setIsIndeterminate(false);
                    setMessage(
                      "Success! Your payment method has been added to your account and your subscription has been updated.",
                    );
                    setAppData({
                      ...appData,
                      active: true,
                    });
                  },
                });
              });
              break;

            case "processing":
              setMessage("Processing payment details...");
              break;

            case "requires_payment_method":
              setIsIndeterminate(false);
              setMessage("Failed to process payment details. Please try another payment method.");
              clearInterval(interval);
              break;
          }
        })
        .catch((error) => {
          setIsIndeterminate(false);
          setMessage("Failed to retrieve the setup intent details.");
        });
    }, 1000);
    return () => clearInterval(interval);
  }, [stripe, appData.jwtToken]);

  return (
    <Box marginTop={"10px"}>
      <CircularProgress isIndeterminate={isIndeterminate} value={100} color="green.300" />
      <Box marginTop={"10px"}>
        <p>{message}</p>
      </Box>
    </Box>
  );
};
