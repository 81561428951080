import { useAuth0 } from "@auth0/auth0-react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Code,
  Heading,
  Link as ChakraLink,
  Link,
  Spinner,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { ApiError, GetOrCreatePaymentConfig } from "../api-client/ApiClient";
import { EnclaveManagerPath, ProvisionInstancePath } from "../App";
import { useAppContext } from "../context/AppState";
import { UserCheck } from "../context/UserCheck";
import { TosCheck } from "../context/TosCheck";
import { GetOrCreatePaymentConfigResponse, PaymentSubscription } from "../gen/proto/kurtosis_backend_server_api_pb";
import { DefaultErrorHeadline, ErrorComponent } from "./ErrorPage";
import {BrowserRecommendator} from "./BrowserRecommendator";

export const formatTime = (timeMs: number) => {
  if (isNaN(timeMs)) return "calculating...";
  const formattedTimeMs = Math.floor(timeMs / 1e3);
  if (formattedTimeMs < 1) return "any second now...";
  return `${toTime(formattedTimeMs)}`;
};

const toTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(14, 19);
};

export const Connect = () => {
  const { appData } = useAppContext();
  const { isLoading } = useAuth0();
  const [error] = useState(false);
  const installCliClipboard = useClipboard("");
  const [installCliCodeText, setInstallCliCodeText] = useState<string[]>([]);
  const cloudLoadClipboard = useClipboard("");
  const [cloudLoadCodeText, setCloudLoadCodeText] = useState<string[]>([]);
  const cloudUnloadClipboard = useClipboard("");
  const [cloudUnloadCodeText, setCloudUnloadCodeText] = useState<string[]>([]);
  const [paymentSubscription, setPaymentSubscription] = useState<PaymentSubscription>();
  const navigate = useNavigate();
  const debouncedPaymentConfig = debounce(getOrCreatePaymentConfig, 2000, { leading: true });
  const debouncedPaymentConfigCallback = useCallback(debouncedPaymentConfig, []);

  useEffect(() => {
    const installCliCode = () => [`brew install kurtosis-tech/tap/kurtosis-cli`];
    // @ts-ignore
    const installCliCodeTextString = installCliCode().join("\n");
    installCliClipboard.setValue(installCliCodeTextString);
    const installCliCodeTextFormattedString: string[] = installCliCode().map((value) => {
      return `\$ ${value}`;
    });
    setInstallCliCodeText(installCliCodeTextFormattedString);

    const cloudLoadCode = () => [
      `export KURTOSIS_CLOUD_API_KEY="${appData.apiKey}"`,
      `kurtosis cloud load ${appData.instanceId}`,
    ];
    // @ts-ignore
    const cloudLoadCodeTextString = cloudLoadCode().join("\n");
    cloudLoadClipboard.setValue(cloudLoadCodeTextString);
    const cloudLoadCodeTextFormattedString: string[] = cloudLoadCode().map((value) => {
      return `\$ ${value}`;
    });
    setCloudLoadCodeText(cloudLoadCodeTextFormattedString);

    const cloudUnloadCode = () => [`kurtosis context set default`];
    // @ts-ignore
    const cloudUnloadCodeTextString = cloudUnloadCode().join("\n");
    cloudUnloadClipboard.setValue(cloudUnloadCodeTextString);
    const cloudUnloadCodeTextFormattedString: string[] = cloudUnloadCode().map((value) => {
      return `\$ ${value}`;
    });
    setCloudUnloadCodeText(cloudUnloadCodeTextFormattedString);

    if (appData.jwtToken) {
      const setupIntent = false;
      debouncedPaymentConfigCallback(appData.jwtToken, setupIntent);
    }
  }, [appData.jwtToken, appData.apiKey, appData.instanceId]);

  function getOrCreatePaymentConfig(accessToken: string, setupIntent: boolean) {
    GetOrCreatePaymentConfig(accessToken, setupIntent)
      .then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while querying for payment config", error);
          },
          Ok(value: GetOrCreatePaymentConfigResponse) {
            if (value.userId && value.userId.length > 0) {
              console.log(`got payment user ID ${value.userId} from database`);
              setPaymentSubscription(value.subscription);
            } else {
              console.log(`Did not get payment config from database`);
            }
          },
        });
      })
      .catch((error) => console.error("An error occurred while getting the payment config", error))
      .finally(() => debouncedPaymentConfig.cancel());
  }

  // Manage the countdown and the progress bar

  const searchParams = new URLSearchParams(window.location.search);
  if (appData?.instanceStatus && appData?.instanceStatus !== "running") {
    navigate(`${ProvisionInstancePath}?${searchParams}`);
    return <></>;
  } else {
    return (
      <>
        <BrowserRecommendator />
        <UserCheck />
        <TosCheck />
        {error && <ErrorComponent headline={DefaultErrorHeadline} content={"An error has occurred"} />}
        {!error && (isLoading || !appData.apiKey) && (
          <>
            <Heading as="h2" size="md">
              One moment please
            </Heading>
            <br />
            <Text>Authenticating...</Text>
            <Center>
              <Spinner />
            </Center>
          </>
        )}
        {!appData?.instanceStatus && appData.apiKey && (
          <>
            <Heading as="h2" size="md">
              One moment please
            </Heading>
            <br />
            <Text>Loading instance status...</Text>
            <Center>
              <Spinner />
            </Center>
          </>
        )}
        {appData?.instanceStatus && appData?.instanceStatus === "running" && (
          <>
            <Box margin={"10px"}>
              <Box marginTop={"15px"}>
                <Heading as="h2" size="md">
                  Your instance is ready for use!
                </Heading>
              </Box>

              <Box marginTop={10} marginBottom={10}>
                <ChakraLink
                  as={ReactRouterLink}
                  to={`${EnclaveManagerPath}?${searchParams}`}
                  style={{ textDecoration: "none" }}
                  _focus={{ boxShadow: "none" }}
                >
                  <Button size={"lg"} bg={"rgb(0, 194, 36)"}>
                    Launch in Enclave Manager
                  </Button>
                </ChakraLink>
              </Box>

              <Box marginTop={"15px"}>
                {paymentSubscription !== undefined && (
                  <Text>You have an active subscription.</Text>
                )}
                {paymentSubscription === undefined && (
                  <Text>Your cloud instance will stay up for 12 hours. Please start a subscription to continue using your cloud instance past 12 hours.</Text>
                )}
              </Box>
              <Box marginTop={10} marginBottom={10} position="relative" padding="0">
                <Heading marginTop={"5"} as={"h2"} size={"md"}>
                  Try your instance in the CLI
                </Heading>

                <Box marginTop={"25px"}>
                  <Text>
                    If you haven't already, please install the Kurtosis CLI to interact with the cloud enclave:
                  </Text>
                </Box>
                <Box marginTop={"10px"}>
                  <Code borderRadius={"10"} padding={"20px"}>
                    {installCliCodeText.map((value, index) => (
                      <Text key={index}>{value}</Text>
                    ))}
                    <Button marginTop={"10px"} onClick={installCliClipboard.onCopy}>
                      {installCliClipboard.hasCopied ? "Copied!" : "Copy"}
                    </Button>
                  </Code>
                </Box>
                <Box marginTop={"10px"}>
                  If you are on Linux or Windows, see the&nbsp;
                  <Link href="https://docs.kurtosis.com/install#ii-install-the-cli" isExternal>
                    installation steps <ExternalLinkIcon mx="4px" />
                  </Link>
                </Box>
                <Box marginTop={"25px"}>
                  <Text>Next, run the following commands to connect to your cloud instance:</Text>
                </Box>
                <Box marginTop={"10px"}>
                  <Code borderRadius={"10"} padding={"20px"}>
                    {cloudLoadCodeText.map((value, index) => (
                      <Text key={index}>{value}</Text>
                    ))}
                    <Button marginTop={"10px"} onClick={cloudLoadClipboard.onCopy}>
                      {cloudLoadClipboard.hasCopied ? "Copied!" : "Copy"}
                    </Button>
                  </Code>
                </Box>
                <Box marginTop={"25px"}>
                  <Text>Once you are done using your cloud instance, you can switch back to your local setup:</Text>
                </Box>
                <Box marginTop={"10px"}>
                  <Code borderRadius={"10"} padding={"20px"}>
                    {cloudUnloadCodeText.map((value, index) => (
                      <Text key={index}>{value}</Text>
                    ))}
                    <Button marginTop={"10px"} onClick={cloudUnloadClipboard.onCopy}>
                      {cloudUnloadClipboard.hasCopied ? "Copied!" : "Copy"}
                    </Button>
                  </Code>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </>
    );
  }
};
