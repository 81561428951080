import { Box, Heading, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAppContext } from "../context/AppState";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ProvisionInstancePath } from "../App";
import { UserCheck } from "../context/UserCheck";
import { TosCheck } from "../context/TosCheck";

const tokenMessageName = "jwtToken";
const frameName = "em_frame";

const passJwtToken = (token: string | undefined) => {
  if (token !== undefined) {
    const iframe = document.getElementById(frameName);
    // @ts-ignore
    const windowObject = iframe.contentWindow;
    windowObject.postMessage({ message: tokenMessageName, value: token }, "*");
  }
};

const toUrlFriendlyIp = (ip: string) => {
  return ip.replaceAll(".", "-");
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const EnclaveManager = () => {
  const { appData } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      appData.isLoadingInstance !== undefined &&
      !appData.isLoadingInstance &&
      appData.jwtToken !== undefined &&
      appData.apiKey !== undefined &&
      appData.remoteApiHostIp !== undefined
    ) {
      const call = async () => {
        // Create a small delay so the iframed code doesn't miss the message
        await sleep(0.2 * 1000);
        passJwtToken(appData.jwtToken);
      };
      const apiHost = `${toUrlFriendlyIp(appData.remoteApiHostIp)}`;
      const remoteHost = `https://cloud.kurtosis.com/gateway/ips/${apiHost}/ports/9711/`;
      // const apiHost = "localhost";
      // const remoteHost = `http://localhost:3000/gateway/ips/${apiHost}/ports/9711`;

      const searchParams = new URLSearchParams(window.location.search);
      // adding hyphenated variables as the new standard and keeping the old for backwards compatability for now
      const url = `${remoteHost}` + (window.location.search.length > 0 ? `?${searchParams}` : "");

      Cookies.set("_kurtosis_jwt_token", appData.jwtToken, { sameSite: "lax", domain: "cloud.kurtosis.com" });
      Cookies.set("_kurtosis_api_key", appData.apiKey, { sameSite: "strict", domain: "cloud.kurtosis.com" });
      Cookies.set("_kurtosis_instance_id", appData.instanceId || "Unknown", {
        sameSite: "strict",
        domain: "cloud.kurtosis.com",
      });
      // TODO: Remove the deprecated `kurtosis` cookie once all cloud engines are upgraded beyond 0.85.39:
      Cookies.set("kurtosis", appData.jwtToken, { sameSite: "strict" });

      window.location.href = url;
    }
  }, [appData.isLoadingInstance, appData.jwtToken, appData.apiKey, appData.remoteApiHostIp]);

  useEffect(() => {
    if (appData.instanceStatus !== undefined && appData.instanceStatus !== "running") {
      const searchParams = new URLSearchParams(window.location.search);
      navigate(`${ProvisionInstancePath}?${searchParams}`);
    }
  }, [appData.isLoadingInstance, appData.isLoadingInstance]);

  const loadingState = () => {
    return (
      <>
        <UserCheck />
        <TosCheck />
        <Box>
          <Box marginTop={"15px"}>
            <Heading as="h2" size="md">
              One moment please
            </Heading>
          </Box>

          <Spinner marginTop={10}></Spinner>
          <Box marginTop={10} marginBottom={10}>
            <p>Loading system state...</p>
          </Box>
        </Box>
      </>
    );
  };

  return <>{appData.isLoadingInstance === undefined && loadingState()}</>;
};

export default EnclaveManager;
