import {
  Box,
  Button,
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef, useState } from "react";

import { ApiError, GetInstanceMetrics } from "../api-client/ApiClient";
import { useAppContext } from "../context/AppState";
import { TosCheck } from "../context/TosCheck";
import {
  GetInstanceMetricsResponse,
} from "../gen/proto/kurtosis_backend_server_api_pb";
      
export const MetricsPage = () => {
  const colorWhite = "white";
  const colorBlack = "black";
  const colorGreen = "rgb(0, 194, 36)";
  const buttonOffColor = useColorModeValue(colorWhite, colorBlack);
  const buttonOnColor = useColorModeValue(colorGreen, colorGreen);
  const { appData } = useAppContext();
  const [metricsImage, setMetricsImage] = useState<string>();
  const [button3hColor, setButton3hColor] = useState<string>(buttonOffColor);
  const [button1dColor, setButton1dColor] = useState<string>(buttonOnColor);
  const [button1wColor, setButton1wColor] = useState<string>(buttonOffColor);
  const [button3hLoading, setButton3hLoading] = useState<boolean>(false);
  const [button1dLoading, setButton1dLoading] = useState<boolean>(false);
  const [button1wLoading, setButton1wLoading] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const debouncedInstanceMetrics = debounce(getInstanceMetrics, 2000, { leading: true });
  const debouncedInstanceMetricsCallback = useCallback(debouncedInstanceMetrics, []);
  const themeLight = 0;
  const themeDark = 1;
  const theme = useColorModeValue(themeLight, themeDark);

  useEffect(() => {
    if (appData.jwtToken && appData.instanceId) {
      const interval = 24;
      const timezone = -new Date().getTimezoneOffset();
      debouncedInstanceMetricsCallback(appData.jwtToken, appData.instanceId, interval, timezone, theme, undefined);
    }
  }, [appData.jwtToken, appData.instanceId]);

  function getInstanceMetrics(
      accessToken: string,
      instanceId: string,
      interval: number,
      utcOffset: number,
      theme: number,
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined
  ) {
    GetInstanceMetrics(accessToken, instanceId, interval, utcOffset, theme)
      .then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while querying for instance metrics", error);
            if (setIsLoading !== undefined) {
              setIsLoading(false);
            }
            handleError(error);
          },
          Ok(value: GetInstanceMetricsResponse) {
            if (value.metrics && value.metrics.length > 0) {
              setMetricsImage(value.metrics);
            } else {
              handleError("No instance metrics sent by the server");
            }
            if (setIsLoading !== undefined) {
              setIsLoading(false);
            }
          },
        });
      })
      .catch((error) => console.error("An error occurred while getting the instance metrics", error))
      .finally(() => debouncedInstanceMetrics.cancel());
  }

  function handleIntervalButton(
    interval: number,
    setColor: React.Dispatch<React.SetStateAction<string>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    theme: number,
  ) {
      if (appData.jwtToken && appData.instanceId) {
        clearButtonsColor();
        setColor(buttonOnColor);
        setIsLoading(true);
        const timezone = -new Date().getTimezoneOffset();
        getInstanceMetrics(appData.jwtToken, appData.instanceId, interval, timezone, theme, setIsLoading);
      }
  }

  const clearButtonsColor = () => {
    setButton3hColor(buttonOffColor);
    setButton1dColor(buttonOffColor);
    setButton1wColor(buttonOffColor);
  }

  const handleError = (error: any) => {
    console.error(error);
    setMessage(error);
  };

  const DisplayMetricsLoading = () => {
    return (
      <Box marginTop={10}>
        <Heading as="h2" size="md">
          Loading the instance metrics
        </Heading>
        <Center>
          <Spinner />
        </Center>
        <Box marginTop={10} w="50%">
          {message !== "" && <div>{message}</div>}
        </Box>
      </Box>
    );
  };
  
  const DisplayMetrics = () => {
    const theme = useColorModeValue(themeLight, themeDark);
    return (
      <Box marginTop={10}>
        <SimpleGrid columns={5} spacing={5} width='400px'>
          <Button size={"sm"} bg={button3hColor} variant={"outline"} isLoading={button3hLoading} onClick={() => handleIntervalButton(3, setButton3hColor, setButton3hLoading, theme)} >
            3h
          </Button>
          <Button size={"sm"} bg={button1dColor} variant={"outline"} isLoading={button1dLoading} onClick={() => handleIntervalButton(24, setButton1dColor, setButton1dLoading, theme)} >
            1d
          </Button>
          <Button size={"sm"} bg={button1wColor} variant={"outline"} isLoading={button1wLoading} onClick={() => handleIntervalButton(24 * 7, setButton1wColor, setButton1wLoading, theme)} >
            1w
          </Button>
        </SimpleGrid>
        <Box py={10}>
          <img src={`data:image/jpeg;base64,${metricsImage}`} />
        </Box>
        <Box marginTop={10} w="50%">
          {message !== "" && <div>{message}</div>}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <TosCheck />
      {metricsImage === undefined && DisplayMetricsLoading()}
      {metricsImage !== undefined && DisplayMetrics()}
    </>
  );
};
