import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TosPagePath } from "../App";
import { useAppContext } from "./AppState";

export const TosCheck = () => {
  const { appData } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (appData.tosAck !== undefined && appData.tosAck === false) {
      navigate(TosPagePath);
    }
  }, [appData.tosAck]);

  return <></>;
};
