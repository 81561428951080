import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { SubscriptionPagePath } from "../App";

export const Deactivated = () => {
  return (
    <>
      <Center>
        <Box mt={"100px"} width={"600px"}>
          <Alert status="error" overflowY={"auto"} maxHeight={"300px"} alignItems={"flex-start"}>
            <AlertIcon />
            <Flex flexDirection={"column"} width={"100%"} gap={"8px"}>
              <Flex direction={"row"}>
                <AlertTitle>User Account Deactivated</AlertTitle>
                <AlertDescription></AlertDescription>
              </Flex>
              <Text>Your user account has been disabled.</Text>
              <Text>
                If you are on an expired trial, consider starting a&nbsp;
                <ChakraLink textDecoration={"underline"} as={ReactRouterLink} to={SubscriptionPagePath}>
                  subscription
                </ChakraLink>
                &nbsp;to continue your service.
              </Text>
              <Text>
                If you believe an error has occurred, please reach out at support@kurtosis.com so we can assist you.
              </Text>
            </Flex>
          </Alert>
        </Box>
      </Center>
    </>
  );
};
