import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Img,
  Link as ChakraLink,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, ReactText } from "react";
import { IconType } from "react-icons";
import { FaDiscord, FaGithub } from "react-icons/fa";
import { FiActivity, FiBarChart2, FiCreditCard, FiMenu, FiMonitor } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { LoginButton } from "./components/buttons/LoginButton";
import { LogoutButton } from "./components/buttons/LogoutButton";
import { DiscordLink, GithubLink, LogoDark, LogoLight } from "./Settings";

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
  protectedPath: boolean;
}

const LinkItems: Array<LinkItemProps> = [
  // {name: 'Create', icon: FiBox, path: '/provision-instance', protectedPath: true},
  { name: "Connect", icon: FiActivity, path: "/connect", protectedPath: true },
  { name: "Enclave Manager", icon: FiMonitor, path: "/enclave-manager", protectedPath: true },
  { name: "Subscription", icon: FiCreditCard, path: "/subscription", protectedPath: true },
  { name: "Metrics", icon: FiBarChart2, path: "/metrics", protectedPath: true },
  // {name: 'Delete Instance', icon: FiDelete, path: "/delete-instance", protectedPath: true},
];

const logInOutItems = (isAuthenticated: boolean) => {
  return (
    <Box>
      {!isAuthenticated && (
        <>
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <>
          <LogoutButton />
        </>
      )}
    </Box>
  );
};

export default function SidebarWithHeader({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("lightblue.100", "gray.900")}>
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { isAuthenticated } = useAuth0();
  return (
    <Box
      //            transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        // bg={useColorModeValue('black', 'gray.900')}
      >
        <Img src={useColorModeValue(LogoLight, LogoDark)} />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.filter((link) => {
        if (!link.protectedPath) {
          return true;
        } else if (isAuthenticated) {
          return true;
        } else {
          return false;
        }
      }).map((link) => (
        <NavItem key={link.name} icon={link.icon} path={link.path}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  path: string;
  children: ReactText;
}

const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
  return (
    <ChakraLink as={ReactRouterLink} to={path} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          // bg: 'cyan.400',
          bg: "rgb(0, 194, 36)",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </ChakraLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { isAuthenticated, user } = useAuth0();
  return (
    <>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Img
          w={"150px"}
          display={{ base: "flex", md: "none" }}
          // src={useColorModeValue("/logo_black_text_small.png", "/logo_white_text_small.png")}
          src={useColorModeValue(LogoLight, LogoDark)}
        />

        <HStack spacing={{ base: "0", md: "6" }}>
          {/*{logInOutItems(isAuthenticated)}*/}
          <ColorModeSwitcher margin={0} justifySelf="flex-end" />
          <Link href={DiscordLink} isExternal>
            <FaDiscord style={{ color: useColorModeValue("black", "white"), fontSize: "2em" }} />
          </Link>
          <Link href={GithubLink} isExternal>
            <FaGithub style={{ color: useColorModeValue("black", "white"), fontSize: "2em" }} />
          </Link>
          {isAuthenticated && user !== undefined && user.picture !== undefined && (
            <Menu>
              <MenuButton>
                <Tooltip label={user?.name} aria-label="user-name">
                  <Avatar display={{ base: "none", sm: "flex" }} src={user.picture} name={user?.name} />
                </Tooltip>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  {/*<Link href="/profile">Profile</Link>*/}
                  {logInOutItems(isAuthenticated)}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Flex>
    </>
  );
};
