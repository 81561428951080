import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react";

export const NewUserNotAllowed = () => {
  return (
    <>
      <Center>
        <Box mt={"100px"} width={"600px"}>
          <Alert status="error" overflowY={"auto"} maxHeight={"300px"} alignItems={"flex-start"}>
            <AlertIcon />
            <Flex flexDirection={"column"} width={"100%"} gap={"8px"}>
              <Flex direction={"row"}>
                <AlertTitle>New User Not Allowed</AlertTitle>
                <AlertDescription></AlertDescription>
              </Flex>
              <Text>Kurtosis Cloud does not accept new users at this time.</Text>
              <Text>
                If you believe an error has occurred, please reach out at support@kurtosis.com so we can assist you.
              </Text>
            </Flex>
          </Alert>
        </Box>
      </Center>
    </>
  );
};
