import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

export interface State {
  userId: string | undefined;
  active: boolean | undefined;
  externalUserId: string | undefined;
  apiKey: string | undefined;
  instanceId: string | undefined;
  instanceStatus: string | undefined;
  jwtToken: string | undefined;
  remoteApiHostIp: string | undefined;
  instanceCreated: string | undefined;
  isLoadingInstance: boolean | undefined;
  tosAck: boolean | undefined;
}

interface AppState {
  appData: State;
  setAppData: Dispatch<SetStateAction<State>>;
}

const defaultState: State = {
  userId: undefined,
  active: undefined,
  externalUserId: undefined,
  apiKey: undefined,
  instanceId: undefined,
  instanceStatus: undefined,
  jwtToken: undefined,
  remoteApiHostIp: undefined,
  instanceCreated: undefined,
  isLoadingInstance: undefined,
  tosAck: undefined,
};

export const AppContext = createContext<AppState>({
  appData: defaultState,
  setAppData: () => {},
});

// @ts-ignore
export const AppStateProvider = (props) => {
  const [appData, setAppDataTemp] = useState<State>(defaultState);

  const setAppData = (appContext: SetStateAction<State>) => {
    const combined = { ...appData, ...appContext };
    setAppDataTemp(combined);
  };
  const value = { appData, setAppData };
  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);
  if (appContext === undefined) {
    throw new Error("AppContext must be inside a AppStateProvider");
  }
  return appContext;
};
