import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import { CallbackPage } from "./components/CallbackPage";
import { Connect } from "./components/Connect";
import { Deactivated } from "./components/Deactivated";
import { NewUserNotAllowed } from "./components/NewUserNotAllowed";
import EnclaveManager from "./components/EnclaveManager";
import { ProvisionInstance } from "./components/ProvisionInstance";
import { SubscriptionPage } from "./components/SubscriptionPage";
import { SubscriptionPaymentPage } from "./components/SubscriptionPaymentPage";
import { MetricsPage } from "./components/MetricsPage";
import { Tos } from "./components/Tos";
import SidebarWithHeader from "./MainPage";

export const RootPath: string = "/";
export const ProvisionInstancePath: string = "/provision-instance";
export const SubscriptionPagePath: string = "/subscription";
export const SubscriptionPaymentPagePath: string = "/subscription-payment";
export const CallbackPagePath: string = "/callback";
export const EnclaveManagerPath: string = "/enclave-manager";
export const ConnectPagePath: string = "/connect";
export const DeactivatedPagePath: string = "/deactivated";
export const NewUserNotAllowedPagePath: string = "/new-user-not-allowed";
export const TosPagePath: string = "/tos";
export const MetricsPagePath: string = "/metrics";

const basicPage: React.FC = (child: any) => {
  return <SidebarWithHeader children={child} />;
};

const guardedPage = (inner: React.FC<{}> | React.ComponentClass<{}, any>) => {
  return <AuthenticationGuard component={inner} />;
};

export const App: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return (
    <Routes>
      <Route path={RootPath}>
        <Route element={<Navigate replace to={`${EnclaveManagerPath}?${searchParams}`} />} index />
        <Route
          path={EnclaveManagerPath + "/*"}
          element={guardedPage(() => (
            <EnclaveManager />
          ))}
        />
        <Route path={ProvisionInstancePath} element={guardedPage(() => basicPage(<ProvisionInstance />))} />
        <Route path={SubscriptionPagePath} element={guardedPage(() => basicPage(<SubscriptionPage />))} />
        <Route path={SubscriptionPaymentPagePath} element={guardedPage(() => basicPage(<SubscriptionPaymentPage />))} />
        <Route path={ConnectPagePath} element={guardedPage(() => basicPage(<Connect />))} />
        <Route path={MetricsPagePath} element={guardedPage(() => basicPage(<MetricsPage />))} />
        <Route path={CallbackPagePath} element={<CallbackPage />} />
        <Route path={DeactivatedPagePath} element={guardedPage(() => (<Deactivated />))} />
        <Route path={NewUserNotAllowedPagePath} element={guardedPage(() => (<NewUserNotAllowed />))} />
        <Route path={TosPagePath} element={guardedPage(() => (<Tos />))}/>
      </Route>
    </Routes>
  );
};
