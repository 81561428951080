import {
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiError, GetOrCreatePaymentConfig } from "../api-client/ApiClient";
import { useAppContext } from "../context/AppState";
import { TosCheck } from "../context/TosCheck";
import {
  GetOrCreatePaymentConfigResponse,
  PaymentMethod,
  PaymentProduct,
  PaymentSubscription,
} from "../gen/proto/kurtosis_backend_server_api_pb";
import { AddressForm } from "./AddressForm";
import { SubscriptionPaymentPagePath } from "../App";
import {BrowserRecommendator} from "./BrowserRecommendator";

const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_KEY as string);

const GetStripeElementOptions = () => {
  const options: StripeElementsOptions = {
    // Fully customizable with appearance API.
    appearance: {
      theme: useColorModeValue("stripe", "night"),
    },
  };

  return options;
};

const DisplayAddressForm = (accessToken: string) => {
  const options = GetStripeElementOptions();
  return (
    <Elements stripe={stripePromise} options={options}>
      <AddressForm accessToken={accessToken}/>
    </Elements>
  );
};

const DisplayStartSubscriptionDetails = (paymentProduct: PaymentProduct) => {
  return (
    <Box marginTop={"10px"}>
      <p>
        To start your {paymentProduct.name} subscription billed {paymentProduct.priceUnitAmount / 100}&nbsp;
        {paymentProduct.priceCurrency.toUpperCase()} monthly, please enter your address below and a payment method on the next page. You will be able to cancel your subscription at any time.
      </p>
    </Box>
  );
};

export const SubscriptionPage = () => {
  const { appData } = useAppContext();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentProduct, setPaymentProduct] = useState<PaymentProduct>();
  const [paymentSubscription, setPaymentSubscription] = useState<PaymentSubscription>();
  const debouncedPaymentConfig = debounce(getOrCreatePaymentConfig, 2000, { leading: true });
  const debouncedPaymentConfigCallback = useCallback(debouncedPaymentConfig, []);

  useEffect(() => {
    if (appData.jwtToken) {
      const setupIntent = false;
      debouncedPaymentConfigCallback(appData.jwtToken, setupIntent);
    }
  }, [appData.jwtToken]);

  function getOrCreatePaymentConfig(accessToken: string, setupIntent: boolean) {
    GetOrCreatePaymentConfig(accessToken, setupIntent)
      .then((res) => {
        res.match({
          Err(error: ApiError) {
            console.error("Error while querying for payment config", error);
            handleError(error);
          },
          Ok(value: GetOrCreatePaymentConfigResponse) {
            if (value.userId && value.userId.length > 0) {
              console.log(`got payment user ID ${value.userId} from database`);
              setPaymentMethod(value.paymentMethod);
              setPaymentProduct(value.product);
              setPaymentSubscription(value.subscription);
            } else {
              console.log(`Did not get payment config from database`);
            }
          },
        });
      })
      .catch((error) => console.error("An error occurred while getting the payment config", error))
      .finally(() => debouncedPaymentConfig.cancel());
  }

  const handleError = (error: any) => {
    console.error(error);
  };

  return (
    <>
      <BrowserRecommendator />
      <TosCheck />
      {paymentSubscription !== undefined && navigate(SubscriptionPaymentPagePath)}
      {paymentSubscription === undefined && paymentProduct !== undefined && DisplayStartSubscriptionDetails(paymentProduct)}
      {paymentSubscription === undefined && paymentProduct !== undefined && appData.jwtToken !== undefined && DisplayAddressForm(appData.jwtToken)}
    </>
  );
};
