export const KurtosisCloudApiDefault = "https://cloud.kurtosis.com:8080";
export const LogoLight = "/KURTOSIS_CLOUD_logo_horizontal_light.png";
export const LogoDark = "/KURTOSIS_CLOUD_logo_horizontal_dark.png";
export const KurtosisCloudAuth0Audience = "https://cloud.kurtosis.com";
export var MainWebsite: string = "https://www.kurtosis.com";
export const Auth0Audience = "https://cloud.kurtosis.com";
export const DiscordLink = "https://discord.com/invite/6Jjp9c89z9";
export const GithubLink = "https://github.com/kurtosis-tech/kurtosis/";
export const SupportEmail = "mailto:support@kurtosistech.com";
export const TosAckCookieName = "_kurtosis_tos_ack";
export const TosAckCookieExpirationDays = 365;
export const TosVersion = "v1";
export const SupportedCountries = ["US", "IN", "TH", "MY", "CH", "CN", "VN"];
