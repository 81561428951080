import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";
import { RootPath } from "../../App";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () =>
    await loginWithRedirect({
      appState: {
        returnTo: RootPath,
      },
    });

  return <Button onClick={handleLogin}>Log In</Button>;
};

export default LoginButton;
