import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DeactivatedPagePath } from "../App";
import { useAppContext } from "./AppState";

export const UserCheck = () => {
  const { appData } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (appData.active !== undefined && !appData.active) {
      console.log(`appData.active=${appData.active}`);
      navigate(DeactivatedPagePath);
    }
  }, [appData.active]);

  return <></>;
};
