import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";

export const DefaultErrorHeadline = "There was an issue";

// @ts-ignore
export const ErrorComponent = ({ headline, content }) => {
  return (
    <>
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {headline}
        </AlertTitle>
        <AlertDescription maxWidth="sm">{content}</AlertDescription>
      </Alert>
    </>
  );
};
