import { Box, Button } from "@chakra-ui/react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import React, { useState } from "react";
import { SubscriptionPaymentPagePath } from "../App";
import { PaymentSubscription } from "../gen/proto/kurtosis_backend_server_api_pb";

interface PaymentMethodFormProps {
  paymentSubscription: PaymentSubscription | undefined;
}

export const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({paymentSubscription}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log("handleSubmit");
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }

    if (paymentSubscription === undefined) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + SubscriptionPaymentPagePath,
        },
      });

      if (error) {
        handleError(error);
      }
    } else {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.origin + SubscriptionPaymentPagePath,
        },
      });

      if (error) {
        handleError(error);
      }
    }
  };

  const handleError = (error: StripeError) => {
    if (error.message !== undefined) {
      setMessage(error.message);
    } else {
      setMessage("Internal error");
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box marginTop={10} marginBottom={10} w="50%">
        <PaymentElement />
      </Box>
      <Box marginTop={10} marginBottom={10} w="50%">
        <Button size={"lg"} bg={"rgb(0, 194, 36)"} disabled={!stripe} type="submit">
          Submit
        </Button>
        <Box marginTop={10} marginBottom={10} w="50%">
          {message !== "" && <div>{message}</div>}
        </Box>
      </Box>
    </form>
  );
};
