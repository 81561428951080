import React from "react";

export function setIntervalImmediately(func: () => void, interval: number) {
  func();
  return setInterval(func, interval);
}

export const startHandler = (ref: any, func: () => void, intervalTimeMs: number) => {
  if (ref.current) return;
  return setIntervalImmediately(func, intervalTimeMs);
};

export const stopHandler = (ref: React.MutableRefObject<undefined>) => {
  clearInterval(ref.current);
  // @ts-ignore
  ref.current = null;
};
