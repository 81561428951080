import { Result } from "true-myth";
import { KurtosisCloudApiDefault } from "../Settings";

import { createPromiseClient } from "@bufbuild/connect";
import { Empty } from "@bufbuild/protobuf";
import { KurtosisCloudBackendServer } from "../gen/proto/kurtosis_backend_server_api_connect";

import { createConnectTransport } from "@bufbuild/connect-web";
import {
  GetCloudInstanceConfigResponse,
  GetInstanceMetricsResponse,
  GetOrCreateApiKeyResponse,
  GetOrCreateInstanceResponse,
  GetOrCreatePaymentConfigResponse,
} from "../gen/proto/kurtosis_backend_server_api_pb";

export interface ApiError {
  data: any;
  message: string;
}

const hostname = process.env.REACT_APP_KURTOSIS_CLOUD_API_HOST || KurtosisCloudApiDefault;

const transport = createConnectTransport({
  baseUrl: hostname,
});
const client = createPromiseClient(KurtosisCloudBackendServer, transport);

// @ts-ignore
export const getOrCreateInstance: (apiKey: string) => Promise<Result<GetOrCreateInstanceResponse, ApiError>> = async (
  apiKey: string,
) => {
  if (apiKey.length < 1) {
    return Promise.resolve(Result.err("No api key provided"));
  }
  return await client
    .getOrCreateInstance({ apiKey: apiKey })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const createApiKey: (accessToken: string) => Promise<Result<GetOrCreateApiKeyResponse, ApiError>> = async (
  accessToken: string,
) => {
  if (accessToken.length < 1) {
    return Promise.resolve(Result.err("No access token provided"));
  }
  return await client
    .getOrCreateApiKey({ accessToken: accessToken })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const getCloudInstanceConfig: (
  instanceId: string,
  apiKey: string,
) => Promise<Result<GetCloudInstanceConfigResponse, ApiError>> = async (instanceId: string, apiKey: string) => {
  if (instanceId.length < 1 || apiKey.length < 1) {
    return Promise.resolve(Result.err("No instance id or api key provided"));
  }
  return await client
    .getCloudInstanceConfig({ instanceId: instanceId, apiKey: apiKey })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const GetOrCreatePaymentConfig: (
  jwtToken: string,
  setupIntent: boolean,
) => Promise<Result<GetOrCreatePaymentConfigResponse, ApiError>> = async (jwtToken: string, setupIntent: boolean) => {
  if (jwtToken.length < 1) {
    return Promise.resolve(Result.err("No jwt token provided"));
  }
  return await client
    .getOrCreatePaymentConfig({ accessToken: jwtToken, setupIntent: setupIntent })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const RefreshDefaultPaymentMethod: (
  jwtToken: string,
  paymentMethodId: string,
) => Promise<Result<Empty, ApiError>> = async (jwtToken: string, paymentMethodId: string) => {
  if (jwtToken.length < 1) {
    return Promise.resolve(Result.err("No jwt token provided"));
  }
  if (paymentMethodId.length < 1) {
    return Promise.resolve(Result.err("No payment method ID provided"));
  }
  return await client
    .refreshDefaultPaymentMethod({ accessToken: jwtToken, paymentMethodId: paymentMethodId })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const CancelPaymentSubscription: (jwtToken: string, reason: string) => Promise<Result<Empty, ApiError>> = async (
  jwtToken: string,
  reason: string,
) => {
  if (jwtToken.length < 1) {
    return Promise.resolve(Result.err("No jwt token provided"));
  }
  return await client
    .cancelPaymentSubscription({ accessToken: jwtToken, reason: reason })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const UpdateAddress: (jwtToken: string, name: string, line1: string, line2: string | undefined, city: string, state: string, postalCode: string, country: string) => Promise<Result<Empty, ApiError>> = async (
  jwtToken: string, name: string, line1: string, line2: string | undefined, city: string, state: string, postalCode: string, country: string
) => {
  if (jwtToken.length < 1) {
    return Promise.resolve(Result.err("No jwt token provided"));
  }
  return await client
    .updateAddress({ accessToken: jwtToken, name: name, line1: line1, line2: line2, city: city, state: state, postalCode: postalCode, country: country })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

// @ts-ignore
export const GetInstanceMetrics: (
  jwtToken: string,
  instanceId: string,
  interval: number,
  utcOffset: number,
  theme: number
) => Promise<Result<GetInstanceMetricsResponse, ApiError>> = async (jwtToken: string, instanceId: string, interval: number, utcOffset: number, theme: number) => {
  if (jwtToken.length < 1) {
    return Promise.resolve(Result.err("No jwt token provided"));
  }
  if (instanceId.length < 1) {
    return Promise.resolve(Result.err("No instance id provided"));
  }
  if (interval <= 0) {
    return Promise.resolve(Result.err("Non stricly positive interval provided"));
  }
  return await client
    .getInstanceMetrics({ accessToken: jwtToken, instanceId: instanceId, interval: interval, utcOffset: utcOffset, theme: theme })
    .then((data) => {
      return Result.ok(data);
    })
    .catch((e) => {
      return Result.err({ message: e.message });
    });
};

